import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/DashboardPage",
          name: "DashboardPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/DashboardPage.vue"),
        },
        {
          path: "/NatureCamps",
          name: "NatureCamps",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/NatureCamps.vue"),
        },



        {
          path: "/CampDetailedView",
          name: "CampDetailedView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/CampDetailedView.vue"),
        },
        {
          path: "/CampDetailedViewClerk",
          name: "CampDetailedViewClerk",
          meta: {
            requireAuth: true, roles: ['clerk']
          },
          component: () => import("./components/sectionclerk/CampDetailedViewClerk.vue"),
        },
        {
          path: "/editbooking",
          name: "editbooking",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/EditMyBookings.vue"),
        },
        {
          path: "/ForwardedApplications",
          name: "ForwardedApplications",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/user/ForwardedApplications.vue"),
        },
        {
          path: "/NatureCampDetails",
          name: "NatureCampDetails",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/NatureCampDetails.vue"),
        },
        {
          path: "/ApplicantDetails",
          name: "ApplicantDetails",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/ApplicantDetails.vue"),
        },
        {
          path: "/sectionhome",
          name: "sectionhome",
          meta: {
            requireAuth: true, roles: ['clerk']
          },
          component: () => import("./components/sectionclerk/Home.vue"),
        },
        {
          path: "/T1page",
          name: "T1page",
          meta: {
            requireAuth: true, roles: ['clerk']
          },
          component: () => import("./components/sectionclerk/tOnepage.vue"),
        },
        {
          path: "/T4page",
          name: "T4page",
          meta: {
            requireAuth: true, roles: ['clerk']
          },
          component: () => import("./components/sectionclerk/tFourpage.vue"),
        },
        {
          path: "/T8page",
          name: "T8page",
          meta: {
            requireAuth: true, roles: ['clerk']
          },
          component: () => import("./components/sectionclerk/tEightpage.vue"),
        },
        {
          path: "/forwardedsection",
          name: "forwardedsection",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/sectionclerk/forwardedsection.vue"),
        },

        {
          path: "/superintedent",
          name: "superintedent",
          meta: {
            requireAuth: true, roles: ['superintendent']
          },
          component: () => import("./components/superintedent/superintedent.vue"),

        },
        {
          path: "/admin-applications",
          name: "admin-applications",
          meta: {
            requireAuth: true, roles: ['admin']
          },
          component: () => import("./components/superAdmin/fullApplications.vue"),

        },
        {
          path: "/t2page",
          name: "t2page",
          meta: {
            requireAuth: true, roles: ['superintendent']
          },
          component: () => import("./components/superintedent/tTwopage.vue"),

        },
        {
          path: "/t5page",
          name: "t5page",
          meta: {
            requireAuth: true, roles: ['superintendent']
          },
          component: () => import("./components/superintedent/tFivepage.vue"),

        }, {
          path: "/t9page",
          name: "t9page",
          meta: {
            requireAuth: true, roles: ['superintendent']
          },
          component: () => import("./components/superintedent/tNinepage.vue"),

        },
        {
          path: "/DetailedViewsuperintedent",
          name: "DetailedViewsuperintedent",
          meta: {
            requireAuth: true, roles: ['superintendent']
          },
          component: () => import("./components/superintedent/CampDetviewsuperint.vue"),

        },


        {
          path: "/mybooking",
          name: "mybooking",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/user/MyBooking.vue"),
        },
        {
          path: "/cancelledapplications",
          name: "cancelledapplications",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/user/CancelledRequests.vue"),
        },
        {
          path: "/CancelledApplicationView",
          name: "CancelledApplicationView",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/CancelledAppView.vue"),
        },

        {
          path: "/booknow",
          name: "booknow",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/user/BookNatureCamp.vue"),
        },
        {
          path: "/cancelled",
          name: "cancelled",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/CanclledBooking.vue"),
        },
        {
          path: "/tomorrow",
          name: "tomorrow",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/BookinsTomorrow.vue"),
        },
        {
          path: "/PendingApplicationsAdmin",
          name: "PendingApplicationsAdmin",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/PendingApplicationsAdmin.vue"),
        },
        {
          path: "/AdminBookinsPage",
          name: "AdminBookinsPage",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/AdminBookinsPage.vue"),
        },

        {
          path: "/CampDetailedViewAdmin",
          name: "CampDetailedViewAdmin",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/CampDetailedViewAdmin.vue"),
        },
        {
          path: "/view",
          name: "view",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/BookingDetailedview.vue"),
        },
        {
          path: "/sectionclerk",
          name: "sectionclerk",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/sectionclerk.vue"),
        },

        {
          path: "/superintedentdivision",
          name: "superintedentdivision",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/superintedent.vue"),
        },
        {
          path: "/cancelrequests",
          name: "cancelrequests",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/CancelRequests.vue"),
        },
        {
          path: "/cancelview",
          name: "cancelview",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/CancelView.vue"),
        },

        {
          path: "/bookib",
          name: "bookib",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/IbBook.vue"),
        },
        {
          path: "/DivisionMybooking",
          name: "DivisionMybooking",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/DivisionMybooking.vue"),
        },

        {
          path: "/ApplicationDivision",
          name: "ApplicationDivision",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/ApplicationDivision.vue"),
        },
        {
          path: "/RefundedApplications",
          name: "RefundedApplications",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/RefundedApplications.vue"),
        },
        {
          path: "/t3page",
          name: "t3page",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/tThreepage.vue"),
        },
        {
          path: "/T6page",
          name: "T6page",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/tSixpage.vue"),
        },
        {
          path: "/T10page",
          name: "T10page",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/tTenpage.vue"),
        },
        {
          path: "/CampDetailedViewDivision",
          name: "CampDetailedViewDivision",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/CampDetailedViewDivision.vue"),
        },
        {
          path: "/FilmDetailedView",
          name: "FilmDetailedView",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/FilmDetailedView.vue"),
        },
        {
          path: "/AdminFilmDetailedView",
          name: "AdminFilmDetailedView",
          meta: {
            requireAuth: true, roles: ['admin']
          },
          component: () => import("./components/superAdmin/AdminFilmDetailedView.vue"),
        },


        {
          path: "/report",
          name: "report",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/Report.vue"),
        },

        {
          path: "/divisiondashboard",
          name: "divisiondashboard",
          meta: {
            requireAuth: true, roles: ['division']
          },
          component: () => import("./components/division/Dashboard.vue"),
        },
        {
          path: "/records",
          name: "records",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/RecordsPage.vue"),
        },
        {
          path: "/timeline",
          name: "timeline",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/timeline.vue"),
        },
        {
          path: "/detailedviewapplicant",
          name: "detailedviewapplicant",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/user/UserDetailed.vue"),
        },
        {
          path: "/paywaitscreen",
          name: "paywaitscreen",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/Payment/PayWaitScreen.vue"),
        },
        {
          path: "/SuccessPage",
          name: "SuccessPage",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/Payment/SuccessPage.vue"),
        },
        {
          path: "/FailurePage",
          name: "FailurePage",
          meta: {
            requireAuth: true, roles: ['user']
          },
          component: () => import("./components/Payment/FailurePage.vue"),
        },

        {
          path: "/CancelledBookings",
          name: "CancelledBookings",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/CancelledBookings.vue"),
        },
        {
          path: "/BookingTomorrow",
          name: "BookingTomorrow",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/BookingTomorrow.vue"),
        },
        {
          path: "/rangebookings",
          name: "rangebookings",
          meta: {
            requireAuth: true, roles: ['range']
          },
          component: () => import("./components/Range/RangeBookings.vue"),
        },

        {
          path: "/adminnaturecamp",
          name: "adminnaturecamp",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/NatureCampAdmin.vue"),
        },

        {
          path: "/addcamp",
          name: "addcamp",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/AddNatureCamp.vue"),
        },

        {
          path: "/editib",
          name: "editib",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/EditIb.vue"),
        },
        {
          path: "/test",
          name: "test",


          component: () => import("./components/TestPage"),
        },
      ],
    },
    {
      path: "/",
      name: "LoginPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/LoginPage"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/RegisterPage"),
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/ForgotPassword"),
    },
    {
      path: '/OtpPage',
      name: 'OtpPage',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/OtpPage.vue')
      }
    },
    {
      path: '/verify',
      name: 'verify',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/onetimePass.vue')
      }
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    !store.state.isLoggedIn
  ) {
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }

  if (store.state.isLoggedIn) {
    axios({
      method: "GET",
      url: "/token/authenticate",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //   if (to.name == "LoginPage" && store.state.isLoggedIn) {
  //     const userType = getUserType();
  //     // console.log("User Type:", userType);

  //     if (userType == "admin") {
  //       // console.log("admin");
  //       next({ name: "DashboardPage" });
  //     } 
  //     else if (userType == "user") {
  //       // console.log("user");
  //       next({ name: "mybooking" });
  //     } 
  //     else if (userType == "clerk") {
  //       // console.log("clerk");
  //       next({ name: "sectionhome" });
  //     } 
  //     else if (userType == "superclerk") {
  //       // console.log("superclerk");
  //       next({ name: "superintedent" });
  //     }  else if (userType == "range") {
  //       // console.log("superclerk");
  //       next({ name: "rangebookings" });
  //     } 

  //     else {
  //       next({ name: "divisiondashboard" });
  //       // console.log("division");
  //     }
  //     return;
  //   }

  //   next();
  // });

  if (to.name === 'LoginPage' && store.state.isLoggedIn) {
    redirectToDashboard();
    return;
  }
  if (to.matched.some(route => route.meta.requireAuth)) {
    const userType = getUserType();
    const isAuthorized = to.matched.every(route => {
      return route.meta.roles ? route.meta.roles.includes(userType) : true;
    });

    if (!isAuthorized) {
      next({ name: '404PageNotFound' });
      return;
    }
  }

  next();
});

function getUserType() {
  const uType = localStorage.getItem("uType");
  // console.log("uType:", uType);   
  if (uType === "777") {
    return "admin";
  }
  else if (uType === "772") {
    return "user";

  }
  else if (uType === "7741") {
    return "clerk";

  }
  else if (uType === "7742") {
    return "superintendent";

  }
  else if (uType === "773") {
    return "range";

  }
  else {
    return "division";
  }
}

function redirectToDashboard() {
  const userType = getUserType();
  console.log('User Type:', userType);

  if (userType === 'admin') {
    console.log('admin');
    router.push({ name: 'admin-applications' });
  } else if (userType === 'user') {
    console.log('user');
    router.push({ name: 'mybooking' });
  } else if (userType === 'clerk') {
    console.log('user');
    router.push({ name: 'sectionhome' });
  } else if (userType === 'superintendent') {
    console.log('user');
    router.push({ name: 'superintedent' });
  } else if (userType === 'range') {
    console.log('user');
    router.push({ name: 'rangebookings' });
  } 
  // else if (userType === 'admin') {
  //   console.log('user');
  //   router.push({ name: 'admin-applications' });
  // } 
  else {
    router.push({ name: 'divisiondashboard' });
    console.log('division');
  }
}

// filmShooting

export default router